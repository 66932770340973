body {
  font-family: 'Montserrat', sans-serif;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
